<template>
    <li class="misc-faq-card__container">
        <div class="misc-faq-card__title-content">
            <h3 class="misc-faq-card__question" @click="showResponse">{{ info.question }}</h3>
            <button class="misc-faq-card__button" :class="{ 'rotate-180': show }" @click="showResponse">
                <img class="misc-faq-card__button-icon" :src="$assets.primary.arrowDown" />
            </button>
        </div>
        <p class="misc-faq-card__description" :class="{ hidden: !show }">
            {{ info.answer }}
        </p>
    </li>
</template>

<script lang="ts">
export default defineComponent({
    name: 'MicroQuestion',
    props: {
        info: {
            type: Object as PropType<{
                question: String
                answer: String
            }>,
            required: true,
        },
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        showResponse() {
            this.show = !this.show
        },
    },
})
</script>

<style lang="postcss" scoped>
.misc-faq-card {
    &__container {
        @apply border-b border-site-background p-6;
    }
    &__title-content {
        @apply flex items-center justify-between gap-6;
    }
    &__question {
        @apply cursor-pointer text-xl font-medium text-site-primary;
    }
    &__description {
        @apply mt-3 text-lg text-site-gray;
    }
    &__button {
        @apply cursor-pointer duration-300 ease-out;

        &-icon {
            @apply duration-300 ease-out;
        }
    }
}
</style>
