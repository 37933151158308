<template>
    <main class="extension-index-page">
        <div class="banner">
            <div class="banner__container">
                <div class="banner-content">
                    <h1 class="extension-index-page__title">
                        {{ $lang.pages.extension.amazing_discounts }}
                    </h1>
                    <p class="extension-index-page__description hidden lg:block">
                        {{ $lang.pages.extension.find_codes }}
                    </p>
                    <a
                        href="https://chrome.google.com/webstore/detail/megadescuentos/gkochegofmljpmgegphbcljdigenjlhd?hl=es"
                        rel="nofollow"
                        target="_blank"
                        class="extension-index-page__button-download extension-index-page__hidden"
                    >
                        <img :src="$assets.misc.buttonDownload" />
                        <p>{{ $lang.pages.extension.install }}</p>
                    </a>
                </div>
                <picture class="banner__picture">
                    <img :src="$assets.illustration.extensionImage" />
                </picture>
            </div>
        </div>
        <div class="banner-button-mobile">
            <a
                href="https://chrome.google.com/webstore/detail/megadescuentos/gkochegofmljpmgegphbcljdigenjlhd?hl=es"
                rel="nofollow"
                target="_blank"
                class="extension-index-page__button-download button-mobile extension-index-page__show"
            >
                <img :src="$assets.misc.buttonDownload" />
                <p>{{ $lang.pages.extension.install }}</p>
            </a>
        </div>
        <div class="extension-index-page-container">
            <section class="extension-services extension-index-page__section">
                <div class="extension-services__service">
                    <img class="extension-services__img" :src="$assets.illustration.extensionImageFree" />
                    <p class="extension-services__title">1. {{ $lang.pages.extension.free }}</p>
                    <p class="extension-services__description">
                        {{ $lang.pages.extension.free_no_cost }}
                    </p>
                </div>
                <div class="extension-services__service">
                    <img class="extension-services__img" :src="$assets.illustration.extensionImageCupon" />
                    <p class="extension-services__title">2. {{ $lang.pages.extension.apply }}</p>
                    <p class="extension-services__description">
                        {{ $lang.pages.extension.we_tell_auto }}
                    </p>
                </div>
                <div class="extension-services__service">
                    <img class="extension-services__img" :src="$assets.illustration.extensionImageRegister" />
                    <p class="extension-services__title">
                        3. {{ $lang.pages.extension.register_not_required }}
                    </p>
                    <p class="extension-services__description">
                        {{ $lang.pages.extension.install_and_go }}
                    </p>
                </div>
            </section>
            <section class="extension-app extension-index-page__section">
                <img :src="$assets.illustration.extensionImageApp" />
                <div class="extension-app__content">
                    <h2 class="extension-index-page__title">{{ $lang.pages.extension.best_extension }}</h2>
                    <p class="extension-index-page__description">
                        {{ $lang.pages.extension.developing }}
                    </p>
                </div>
            </section>
            <section class="extension-shop extension-index-page__section">
                <div class="extension-shop__content">
                    <div class="flex lg:inline-block">
                        <span class="extension-shop__tag">{{ $lang.pages.extension.find_best_savings }}</span>
                    </div>
                    <h2 class="extension-index-page__title">{{ $lang.pages.extension.brands }}</h2>
                    <p class="extension-index-page__description">
                        {{ $lang.pages.extension.best_brands }}
                    </p>
                </div>
                <img class="extension-shop__image" :src="$assets.illustration.extensionImageShop" />
            </section>

            <div class="extension-download">
                <img class="extension-download__image" :src="$assets.illustration.bannerExtensionApp" />
                <div class="extension-download-content">
                    <div class="extension-download__section">
                        <h2 class="extension-index-page__title white-title">
                            {{ $lang.pages.extension.get_now }}
                        </h2>
                        <a
                            href="https://chrome.google.com/webstore/detail/megadescuentos/gkochegofmljpmgegphbcljdigenjlhd?hl=es"
                            rel="nofollow"
                            target="_blank"
                            class="extension-index-page__button-download white-button"
                        >
                            <img :src="$assets.misc.buttonDownloadPurple" />
                            <p>{{ $lang.pages.extension.download_extension }}</p>
                        </a>
                        <p class="extension-index-page__description white-title extension-index-page__hidden">
                            {{ $lang.pages.extension.extension_free }}
                        </p>
                    </div>
                </div>
            </div>

            <section class="extension-faq extension-index-page__section">
                <h2 class="extension-index-page__title">{{ $lang.pages.extension.answers_here }}</h2>
                <ul class="faq-card">
                    <MiscQuestion v-for="(item, index) in faq" :key="index" :info="item" />
                </ul>
            </section>
        </div>
    </main>
</template>

<script lang="ts" setup>
const { $lang } = useNuxtApp()

const faq = [
    {
        question: $lang.pages.extension.is_extension_free,
        answer: $lang.pages.extension.is_extension_free_answer,
    },

    {
        question: $lang.pages.extension.how_install_extension,
        answer: $lang.pages.extension.how_install_extension_answer,
    },

    {
        question: $lang.pages.extension.how_know_works,
        answer: $lang.pages.extension.how_know_works_answer,
    },

    {
        question: $lang.pages.extension.what_shops_discounts,
        answer: $lang.pages.extension.what_shops_discounts_answer,
    },

    {
        question: $lang.pages.extension.how_remove,
        answer: $lang.pages.extension.how_remove_answer,
    },

    {
        question: $lang.pages.extension.works_mobile,
        answer: $lang.pages.extension.works_mobile_answer,
    },
]

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.extension.title_seo
const description = $lang.pages.extension.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.extension-index-page {
    &-container {
        @apply pb-16;
    }
    &__section {
        @apply container mt-12 lg:mt-16;
    }
    &__title {
        @apply mb-2 text-center text-3xl font-semibold lg:text-left lg:text-4xl;
        line-height: 1.3;
    }
    &__description {
        @apply text-left text-xl text-site-gray;
    }
    &__button-download {
        @apply mt-6 flex w-full max-w-max items-center justify-center gap-4 rounded-xl bg-site-primary px-6 py-4 font-semibold text-white sm:w-auto;
        font-size: 22px;
        img {
            @apply h-8 w-8;
        }
    }
    &__hidden {
        @apply hidden lg:flex;
    }
    &__show {
        @apply flex lg:hidden;
    }
    .button-mobile {
        @apply mb-6 mt-0;
    }
    .white-button {
        @apply bg-white text-site-primary;
    }
    .white-title,
    .white-description {
        @apply mt-4 text-center text-white;
    }
    .banner {
        @apply relative w-full bg-extension-mobile-banner bg-cover bg-right-bottom lg:bg-extension-banner;
        height: 420px;

        &__container {
            @apply container relative z-10 flex h-full flex-col items-center justify-center lg:flex-row lg:gap-16;
        }
        &-content {
            @apply lg:w-1/2;
        }
        &-button-mobile {
            @apply container mt-6 flex justify-center;
        }
        &__picture {
            @apply mt-5 lg:m-0;
            width: 250px;
        }
    }
    @screen lg {
        .banner {
            height: 520px;
            &__picture {
                width: auto;
            }
        }
    }
    .extension-services {
        @apply flex flex-col items-center justify-center gap-4 lg:flex-row lg:items-start lg:justify-around lg:gap-0;
        &__service {
            @apply flex flex-col items-center sm:w-1/2 lg:w-1/4;
        }
        &__img {
            @apply mx-auto block h-24 w-24;
        }
        &__title {
            @apply my-2 text-center text-xl font-semibold;
        }
        &__description {
            @apply text-center text-xl text-site-gray;
        }
    }
    .extension-app {
        @apply flex flex-col-reverse items-center justify-center gap-6 lg:flex-row lg:gap-16;
        &__content {
            @apply lg:w-1/2 xl:w-2/5;
        }
    }
    .extension-shop {
        @apply flex flex-col items-center justify-center gap-6 lg:flex-row lg:gap-16;
        &__content {
            @apply lg:w-1/2 xl:w-2/5;
        }
        &__tag {
            @apply mx-auto mb-5 inline-block rounded-full bg-site-primary px-4 py-2 text-sm font-medium text-white lg:m-0;
        }
        &__description {
            @apply text-xl text-site-gray;
        }
    }
    .extension-download {
        @apply relative mt-10;
        &-content {
            @apply absolute left-0 top-0 flex h-full w-full items-center justify-center;
        }
        &__section {
            @apply container mx-auto flex flex-col items-center justify-center bg-none;
        }
        &__image {
            @apply w-full;
            min-height: 300px;
            object-fit: cover;
        }
        @screen lg {
            &__image {
                height: auto;
            }
        }
    }
    .extension-faq {
        &__title {
            @apply mb-2 text-center text-2xl font-semibold lg:text-left lg:text-3xl;
            line-height: 1.3;
        }
        .faq-card {
            @apply mt-6 rounded-3xl bg-white;
        }
    }
}
</style>
